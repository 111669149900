<template>
  <div>
    <div>
      <i :class="trigger.icon"></i> {{ trigger.label }}
      <span v-if="eventsLabel" class="keywords-span">( {{ eventsLabel }} )</span>
    </div>

    <b-modal id="date-modal" v-model="isShowModal" title="Stripe" size="lg">
      <div class="row">
        <div class="col-12">
          <div class="form-group">
            <label>Event List</label>
            <multiselect v-model="automation.options.events" :options="eventList" :multiple="true" track-by="id"
              placeholder="Select Events" label="name"></multiselect>
          </div>
        </div>
        <div v-if="showOptions" class="col-12">
          <div class="form-group">
            <label>Stripe Account</label>
            <multiselect v-model="automation.options.integration_user" :options="options" :multiple="false" :allow-empty="false"
              track-by="id" placeholder="Select Account" label="name" @input="onAccountChange"></multiselect>
          </div>
        </div>
        <div class="col-12">
          <template v-if="loadingProducts">
            <b-spinner small></b-spinner>
          </template>
          <div v-else class="form-group">
            <label>Product</label>
            <multiselect v-model="productSelected" :options="productList" :multiple="true" :allow-empty="true" track-by="id"
              placeholder="Select Product" label="name"></multiselect>
          </div>
          <div class="form-group small-checkbox mt-4">
            <b-form-checkbox v-model="automation.options.email_double_optin" name="email_double_optin">
              Email Double opt-in
              <span v-b-tooltip.hover class="cursor-pointer" title="Requires new subscribers to confirm their email before adding to this list and sending emails." style="vertical-align: middle;">
          <i  class="uil uil-question-circle"></i>
        </span>
            </b-form-checkbox>
          </div>
          <div v-if="automation.options.email_double_optin" class="col-md-12 small-checkbox mt-2">
            <label for="">Redirect URL <span v-b-tooltip.hover class="cursor-pointer" title="User will be redirected to this URL after clicking on confirmation link." style="vertical-align: middle;">
             <i  class="uil uil-question-circle"></i>
          </span></label>
            <TextInput v-model="automation.options.optin_email_redirect_url" rules="url" name="optin_email_redirect_url" />
          </div>
        </div>
      </div>

      <template v-slot:modal-footer>
        <b-button variant="primary" @click="save">Save</b-button>
      </template>
    </b-modal>
  </div>
</template>
    
<script>
import Vue from 'vue'
import Multiselect from 'vue-multiselect'

export default {
  components: {
    Multiselect
  },

  props: {
    automation: {
      type: Object,
      default: null,
    },
    blocks: {
      type: Array,
      default: null,
    },
    trigger: {
      type: Object,
      default: null,
    },
  },

  data() {
    return {
      isShowModal: false,
      loadingProducts: false,
      options: [],
      productList: [],
      eventList: [
        { id: 'product.purchased', name: 'New Purchase' },
        { id: 'payment.failed', name: 'Failed payment' },
        { id: 'customer.created', name: 'New Customer' },
      ],
      eventsSelected: [],
      productSelected: [],
    }
  },

  computed: {
    eventsLabel() {
      if (this.eventsSelected)
        return this.eventsSelected.map(item => item.name).join(', ');
      return ''
    },

    showOptions() {
      return this.options && this.options.length > 1
    },
  },

  mounted() {
    this.$parent.$on('showModal', this.showModal)
    this.automation.options = {
      ...{ email_double_optin: false, optin_email_redirect_url : null },
      ...this.automation.options
    }
    this.productSelected = this.automation.options.product
    this.getSelectOptions()
  },

  methods: {
    save() {
      if (!this.automation.options.events || !this.automation.options.events.length) {
        Vue.$toast.open({
          message: 'Keywords can\'t be empty',
          type: 'error',
          duration: 3000,
        })
        return false;
      }
      this.eventsSelected = this.automation.options.events
      this.automation.options.product = this.productSelected
      this.isShowModal = false
    },

    showModal() {
      this.isShowModal = true
    },

    getSelectOptions() {
      let queries = {
        key: ['stripe']
      }
      this.$store.dispatch('integration/selectOptions', queries)
        .then(options => {
          this.options = options
          if (options && options.length === 1)
          {
            this.automation.options.integration_user = options[0]
          }
          this.getProducts()
        })
        .catch(() => { })
    },

    getProducts() {
      this.loadingProducts = true
      let params = {
        'integration_id': this.automation.options.integration_user ? this.automation.options.integration_user.id : undefined
      }

      this.$store.dispatch('integration/getStripePrices', params)
        .then(res => {
          this.productList = res
          this.loadingProducts = false
        })
        .catch(() => {
          this.loadingProducts = false
        })
    },

    onAccountChange() {
      this.automation.options.product = undefined
      this.getProducts()
    }
  },
}
</script>
    
<style lang="scss" scoped>
.keywords-span {
  display: block;
  font-size: 13px;
  font-weight: normal;
}
</style>
    
<style lang="scss">
#date-modal .small-checkbox {
  .custom-checkbox {
    padding-left: 30px;
    margin-bottom: 5px;
  }

  .custom-control-label:before,
  .custom-control-label:after {
    width: 20px;
    height: 20px;
    left: -30px;
    top: 0px;
  }

  .custom-control-input {
    width: 20px;
    height: 20px;
    top: -4px;
  }
}
</style>